<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card class="primary--border" outlined>
          <v-card-title class="title">
            Event List
            <v-spacer></v-spacer>
            <add-button permission="event-create"  @action="
                (form.dialog = true), $refs.form ? $refs.form.reset() : ''
              ">
              New Event List
            </add-button>
            
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="form.items.data"
            :options.sync="pagination"
            :footer-props="footerProps"
            :server-items-length="form.items.meta.total"
            :loading="form.loading"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td>{{ index + form.items.meta.from }}</td>
                <td class="text-xs-left">{{ item.title }}</td>
                
                <td class="text-xs-left">

                  {{ item.description }}
                </td>
               
                <td class="text-right">
                  <edit-button
                    permission="event-update"
                    @agree="form.edit(item), (save_flag = false)"
                  />
                  <delete-button
                    permission="event-delete"
                    @agree="form.delete(item.id)"
                  />
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="form.dialog" persistent max-width="400px">
      <v-card>
        <v-card-title class="primary white--text">
          <span class="title">Add/Update</span>
        </v-card-title>
        <v-card-text class="pb-1 pt-4">
          <v-form
            ref="form"
            v-model="valid"
            :lazy-validation="lazy"
          >
            <!-- @submit.prevent="save" -->
            <!-- @keyup.enter="save" -->
            <!-- @keydown.native="form.errors.clear($event.target.name)" -->
            <v-text-field
              type="search"
              autocomplete="off"
              label="Title*"
              required
              class="pa-0"
              outlined
              dense
              v-model="form.title"
              name="title"
              :error-messages="form.errors.get('title')"
              :rules="[(v) => !!v || 'Title is required']"
            />
            <v-color-picker
              v-model="form.color_code"
               name="color"
                :error-messages="form.errors.get('color')"
                :rules="[(v) => !!v || 'Color Code is required']"
            ></v-color-picker>
            <!-- <v-color-picker
                dot-size="25"
                swatches-max-height="200"
                v-model="form.color_code"
                name="color"
                :error-messages="form.errors.get('color')"
                :rules="[(v) => !!v || 'Color Code is required']"
            ></v-color-picker> -->
            <v-textarea
                autocomplete="off"
                label="Description"
                class="pa-0"
                outlined
                dense
                no-resize
                v-model="form.description"
              />
            <!-- <input type="color" value="#f6f82" id="colorPicker" width="25px"> -->
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                text
                @click="form.reset(), (save_flag = true), reset()"
                >Close</v-btn
              >
              <v-btn color="success" text @click="validate" v-if="save_flag"
                >Save</v-btn
              >
              <v-btn color="success" text @click="validate" v-else
                >Update</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Form from "@/library/Form";
import Mixins from "@/library/Mixins";
import { redirectDownloadUrl } from '../../../../library/helpers';
export default {
  mixins: [Mixins],
  data: () => ({
    valid: true,
    lazy: false,
    save_flag:true,
    form: new Form(
      {
        dialog: false,
        title: "",
        color_code: "#ff00ff",
        description: "",
       
      },
      "/api/event-type"
    ),

    headers: [
      { text: "#", align: "left", value: "id", sortable: false },
      { text: "Name", align: "left", value: "name", sortable: false },
      {
        text: "Description",
        align: "left",
        value: "description",
        sortable: false,
      },
      
      { text: "Action", align: "right", sortable: false },
    ],
  }),
  mounted(){
    this.get();
  },
  methods:{
    get(){
        this.form.get().then(({ data }) => {
        this.pagination.totalItems = data.meta.total;
      });
    },
     validate() {
      if (this.$refs.form.validate()) {
        this.form.store().then((res) => {
          this.save_flag = true
        }).catch((error)=>{

        });
      }
    },
    

  },
   watch: {
    pagination: function () {
      this.get();
    },
  },
};
</script>
